import React from "react";
import "./FooterStyle.css";

export default function Footer() {
  return (
    <div>
      <div className="footer_container">
        <h3>copyright &copy; to stoom.in 2021-22</h3>
      </div>
    </div>
  );
}
